<template>
  <a class="ui-link" :href="url" v-if="url && !path">
    <slot></slot>
  </a>
  <router-link class="ui-link" tag="a" v-else-if="!url && path" :to="path">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: 'UiLink',
  props: {
    url: {
      type: String,
      required: false,
      default: '',
    },
    path: {
      type: Object,
      required: false,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-link {
  display: inline-flex;
  align-items: center;
  transition: color $transition-duration-default $transition-effect-default;
  text-decoration: none;
  color: $link-color-text;
  font-weight: $link-font-weight;

  &:hover {
    color: $link-color-text-hover;
  }
}
</style>

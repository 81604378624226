<template>
  <form class="login-reset-password" @submit.prevent="onSubmit" novalidate autocomplete="off">
    <div class="login-reset-password__title">
      <ui-title :title="$t('login.resetPassword.title')" align="center" />
    </div>
    <div
      class="login-reset-password__description"
      v-html="
        $t('login.resetPassword.description', {
          email,
        })
      "
    ></div>
    <ui-input
      class="login-reset-password__input"
      v-model="password"
      type="password"
      id="password"
      :label="$t('login.resetPassword.password')"
      icon="lock"
      :reverse="true"
      :error="$v.password.$error"
      design="minimalist"
    >
      <template v-if="$v.password.$error && !$v.password.required">
        {{ $t('errors.required') }}
      </template>
      <template v-else-if="$v.password.$error && !$v.password.minLength">
        {{
          $t('errors.minLength', {
            minLength: $v.password.$params.minLength.min,
          })
        }}
      </template>
    </ui-input>
    <ui-input
      class="login-reset-password__input"
      v-model="passwordConfirmation"
      type="password"
      id="password-confirm"
      :label="$t('login.resetPassword.passwordConfirmation')"
      icon="lock"
      :reverse="true"
      :error="$v.passwordConfirmation.$error"
      design="minimalist"
    >
      <template v-if="$v.passwordConfirmation.$error && !$v.passwordConfirmation.sameAs">
        {{ $t('errors.sameAs') }}
      </template>
    </ui-input>
    <ui-button
      class="login-reset-password__submit"
      button="cta"
      type="submit"
      :label="$t('login.resetPassword.submit')"
      variant="gtr"
      :disabled="sending"
    />
    <div class="login-reset-password__bottom">
      <ui-link :path="{ name: 'SignIn' }">
        {{ $t('login.resetPassword.signIn') }}
      </ui-link>
    </div>
  </form>
</template>

<script>
import UiTitle from '@/components/UI/Title.vue'
import UiInput from '@/components/UI/Input.vue'
import UiLink from '@/components/UI/Link.vue'
import UiButton from '@/components/UI/Button.vue'
import { notification } from '@/utils/notification.util'

import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  name: 'LoginResetPassword',
  components: {
    UiTitle,
    UiInput,
    UiLink,
    UiButton,
  },
  data() {
    return {
      email: '',
      token: '',
      password: '',
      passwordConfirmation: '',
      sending: false,
    }
  },
  mounted() {
    this.token = this.$route.params.token
    this.userCheckPasswordToken(this.token)
      .then(email => {
        this.email = email
      })
      .catch(() => {
        notification({
          text: this.$t('login.resetPassword.notification.error'),
          type: 'error',
        })
        this.$router.push({ name: 'SignIn' })
      })
  },
  methods: {
    ...mapActions({
      userForgetPassword: 'user/userForgetPassword',
      userCheckPasswordToken: 'user/userCheckPasswordToken',
      userResetPassword: 'user/userResetPassword',
    }),
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.sending = true
        this.userResetPassword({ password: this.password, token: this.token })
          .then(() => {
            notification({
              text: this.$t('login.resetPassword.notification.success'),
              type: 'success',
            })
            this.$router.push({ name: 'SignIn' })
          })
          .catch(() => {
            this.sending = false
            notification({
              text: this.$t('common.notification.error'),
              type: 'error',
            })
          })
      }
    },
  },
  validations() {
    return {
      password: {
        required,
        minLength: minLength(6),
      },
      passwordConfirmation: {
        sameAs: sameAs('password'),
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.login-reset-password {
  &__title {
    display: flex;
    justify-content: center;
    margin-bottom: $gutter-tablet;

    @media (min-width: $screen-xl) {
      margin-bottom: $gutter-desktop;
    }
  }

  &__description {
    margin-bottom: $gutter-tablet;
    text-align: center;

    @media (min-width: $screen-xl) {
      margin-bottom: $gutter-desktop;
    }
  }

  &__input {
    margin-bottom: $gutter-tablet;

    @media (min-width: $screen-xl) {
      margin-bottom: $gutter-desktop;
    }
  }

  &__submit {
    margin-bottom: $gutter-tablet;
    width: 100%;

    @media (min-width: $screen-xl) {
      margin-bottom: $gutter-desktop;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__caption {
      margin-bottom: $gutter-tablet;
      text-align: center;
      color: $generic-color-text-caption;

      @media (min-width: $screen-xl) {
        margin-bottom: $gutter-desktop;
      }
    }
  }
}
</style>
